<template>
  <section>
    <v-icon class="weborder-message-icon" size="200px" color="#00ad06">
      mdi-checkbox-marked-circle
    </v-icon>
    <p class="weborder-message-title">¡Gracias por tu reseña!</p>
    <p class="weborder-message-text">
      Tu opinión nos ayuda a seguir creciendo y brindar un mejor servicio cada
      día.
    </p>
  </section>
</template>
  
<script>
export default {
  name: "ThankYouReview",
};
</script>
<style scoped>
.weborder-message-icon {
  display: flex;
  justify-content: center;
}
.weborder-message-title {
  color: #00ad06;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
}
.weborder-message-text {
  width: 250px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
  font-size: 20px;
}
</style>
  